import React, {Component} from 'react';

export default class DesktopNavigation extends Component {
    render() {
        return (
            <nav className={this.props.sticky ? 'navbar navbar--sticky' : 'navbar'} id='mainNav'>
                <div onClick={() => this.props.goTo('Home')} className='navbar--logo-holder'>
                    <img src={'images/logo_transparent_cut.png'} alt='logo' className='navbar--logo'/>
                    <h1 className={this.props.sticky ? 'navbar--logo-text-sticky' : 'navbar--logo-text'}>Max Erler</h1>
                </div>
                <ul className='navbar--link'>
                    <div onClick={() => this.props.goTo('Home')}>
                        <div
                            className={(this.props.activeElement === 'Home' ? 'active-page ' : '') + (this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item')}>
                            Home
                        </div>
                    </div>
                    <div onClick={() => this.props.goTo('About')}>
                        <div
                            className={(this.props.activeElement === 'About' ? 'active-page ' : '') + (this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item')}>
                            About
                        </div>
                    </div>
                    {/*<div onClick={() => this.props.goTo('Services')}>*/}
                    {/*    <div*/}
                    {/*        className={(this.props.activeElement === 'Services' ? 'active-page ' : '') + (this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item')}>*/}
                    {/*        {t('services.label')}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div onClick={() => this.props.goTo('Projects')}>
                        <div
                            className={(this.props.activeElement === 'Projects' ? 'active-page ' : '') + (this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item')}>
                            Experience
                        </div>
                    </div>
                    <div onClick={() => this.props.goTo('Contact')}>
                        <div
                            className={(this.props.activeElement === 'Contact' ? 'active-page ' : '') + (this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item')}>
                            Contact
                        </div>
                    </div>
                    <a href="https://blog.maxerler.com" rel="noopener noreferrer">
                        <div className={this.props.sticky ? 'navbar--link-item-sticky' : 'navbar--link-item'}>
                            Blog
                        </div>
                    </a>
                </ul>
            </nav>
        );
    }
}
